<!-- UsageProfile Parameters -->
<ng-container [formGroup]="usageProfileFormGroup">
  <div class="row">
    <div class="col">
      <h2 class="bordered default-border-color">
        @switch (getUsageProfileType) { @case ('mainUsage') { Hauptnutzung } @case ('subordinateUsage') { Untergeordnete Nutzung } @case ('sideUsage')
        {Nebennutzung } }
      </h2>
      @for (profileType of projectService.usageProfiles$ | async; track profileType) { @if (profileType.id === getUsageProfileId) {
      {{ usageProfileEnum[profileType.name] }}

      <div class="row">
        <h4>Flächennutzung nach DIN277</h4>
        <mat-icon fontIcon="info" [matTooltip]="tooltips['flaechenberechnungNachDin277']"></mat-icon>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>BGF (R)</mat-label>
          <input matInput formControlName="bgf" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>BRI</mat-label>
          <input matInput formControlName="bri" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NRF (R)</mat-label>
          <input matInput formControlName="nrf" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NUF (R)</mat-label>
          <input matInput formControlName="nuf" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>TF (R)</mat-label>
          <input matInput formControlName="tf" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>VF (R)</mat-label>
          <input matInput formControlName="vf" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>KGF (R)</mat-label>
          <input matInput formControlName="kgf" />
        </mat-form-field>
      </div>
      <div class="row">
        <h3>DGNB-Bemessungsfläche</h3>
        <mat-icon fontIcon="info" [matTooltip]="tooltips['bemessungsflaeche']"></mat-icon>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>ADGNB (R)</mat-label>
          <input matInput formControlName="adgnb" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NF Fahrzeug</mat-label>
          <input matInput formControlName="nfVehicle" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>VF Hallen,Flure</mat-label>
          <input matInput formControlName="vfHallCorridor" />
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>Anteil in %</mat-label>
          <input matInput formControlName="percentage" />
        </mat-form-field>
      </div>
      } }
    </div>
    <div class="col">
      <h2 class="bordered default-border-color">Geschosse</h2>

      @for(floor of floors.controls; track floor){

      <div class="row">
        <button mat-raised-button color="primary" (click)="openFloor($index)" class="btn vertical no-shadow open-floor-button">
          {{ floor.get('name')?.value }}
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-raised-button color="warn" (click)="deleteFloor($index)" class="btn vertical no-shadow delete-floor-button">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      }
      <button mat-raised-button color="primary" (click)="openFloor(null)" class="btn vertical no-shadow">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
