import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CertificationType, FulfillmentLevelEntry } from '@eeule/eeule-shared/src/types';
import { Observable, filter, map, switchMap, tap } from 'rxjs';
import { CommaDecimalInputComponent } from '../../../../core/components/decimal-comma/decimal-comma.component';
import { IndicatorService } from '../../../../core/services/indicator.service';
import { ProjectService } from '../../../../core/services/project.service';
import { LifeCyclePhaseEnum } from '../../../../enums/LifeCyclePhase.enum';
import { UsageProfileEnum } from '../../../../enums/UsageProfile.enum';
import { DescriptionGroupFormGroup, Tooltip } from '../project-info-page/project-info-page.component';
import { CertificationDescriptionGroupComponent } from './certification-description-group/certification-description-group.component';
import { SystemConfigService } from '../../../../core/services/systemConfig/system-config.service';

@Component({
  selector: 'eule-tab-certification',
  standalone: true,
  imports: [
    CertificationDescriptionGroupComponent,
    CommaDecimalInputComponent,
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgTemplateOutlet,
    ReactiveFormsModule,
  ],
  templateUrl: './tab-certification.component.html',
  styleUrl: './tab-certification.component.scss',
})
export class TabCertificationComponent {
  @Input({ required: true }) certificationFormGroup!: FormGroup;
  @Input({ required: true }) totalCertificationDescriptionFormGroup!: FormGroup;

  public displayOnlyFormControl: FormControl = new FormControl({ value: null, disabled: true });
  public systemErfuellungsGrade$: Observable<FulfillmentLevelEntry[] | undefined> = this.projectService.project$.pipe(
    filter(proj => proj !== null), // Skip Projects that are null
    switchMap(proj => this._indicatorService.getDgnbSystem(proj!.dgnbSystem!)),
    tap(system => {
      const displayName: string = `${system.name} ${system.certificationVersion}`;
      this.displayOnlyFormControl.setValue(displayName);
    }),
    map(sys => sys.erfuellungsGradSystem)
  );

  public euTaxonomyErfuellungsGrade$: Observable<CertificationType[]> = this._systemConfigService.getLiveSystemConfigTypesEuTaxonomy();
  // .pipe(map((grade: CertificationType[]) => [{ id: null, value: 'Nein' } as unknown as CertificationType, ...grade]));
  public qngErfuellungsGrade$: Observable<CertificationType[]> = this._systemConfigService.getLiveSystemConfigTypesQng();
  public projectCertificationLevels$: Observable<CertificationType[]> = this._systemConfigService.getLiveSystemConfigTypesProjectCertificationLevels();

  get certificationDescriptionFormArray() {
    return this.totalCertificationDescriptionFormGroup.get('certificationDescriptionGroups') as FormArray<FormGroup<DescriptionGroupFormGroup>>;
  }

  public lifeCyclePhaseEnum: typeof LifeCyclePhaseEnum = LifeCyclePhaseEnum;
  public usageProfileEnum: typeof UsageProfileEnum = UsageProfileEnum;

  public tooltips: Tooltip = {
    hasMixedUsageProfiles: `Mischnutzung
    Befinden sich im betrachteten Gebäude mehrere unterschiedliche Nutzungen, muss überprüft werden, welches Nutzungsprofil anzuwenden ist. Grundlage für die Einschätzung, ob die Zertifizierung nach MIX23 stattfinden muss, bildet die DGNB Bemessungsfläche.
    Es wird empfohlen die Einstufungen unterschiedlicher Nutzungen mit der DGNB Geschäftsstelle abzustimmen.
    Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
  };

  public constructor(public projectService: ProjectService, private _indicatorService: IndicatorService, public _systemConfigService: SystemConfigService) {}

  /**
   * Vergleichsfunktion, um Objekte basierend auf ihren Attributen zu vergleichen.
   * Als Resultat werden Elemente aufsteigend sortiert.
   *
   * @param {*} c1
   * @param {*} c2
   * @returns {boolean}
   *
   * @memberOf TabCertificationComponent
   */
  compareErfuellungsGrade(c1: FulfillmentLevelEntry, c2: FulfillmentLevelEntry): boolean {
    return c1 && c2 ? c1.minimumPercentage === c2.minimumPercentage && c1.name === c2.name : c1 === c2;
  }
}
