<h2 mat-dialog-title>Geschossparameter</h2>
@if(floorForm){
<mat-dialog-content>
  <div id="indicator-dialog-container">
    <form [formGroup]="floorForm!">
      <div class="row">
        <mat-form-field class="login-input">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </div>
      <div class="row">
        <h4>Flächennutzung nach DIN277</h4>
        <mat-icon fontIcon="info" [matTooltip]="tooltips['flaechenberechnungNachDin277']"></mat-icon>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>BGF (R)</mat-label>
          <eule-comma-decimal-input formControlName="bgf"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>BRI</mat-label>
          <eule-comma-decimal-input formControlName="bri"></eule-comma-decimal-input>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NRF (R)</mat-label>
          <eule-comma-decimal-input formControlName="nrf"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NUF (R)</mat-label>
          <eule-comma-decimal-input formControlName="nuf"></eule-comma-decimal-input>
          <!-- <input matInput formControlName="nuf" />
          @if(floorForm.get('vfHallCorridor')!.hasError('adgnbNegative')){
          <mat-error> ADGNB darf nicht negativ werden. </mat-error>
          } @if(floorForm.get('vfHallCorridor')!.hasError('pattern')){
          <mat-error>Eingabe muss deutschem Zahlenformat entsprechen</mat-error>
          } -->
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>TF (R)</mat-label>
          <eule-comma-decimal-input formControlName="tf"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>VF (R)</mat-label>
          <eule-comma-decimal-input formControlName="vf"></eule-comma-decimal-input>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>KGF (R)</mat-label>
          <eule-comma-decimal-input formControlName="kgf"></eule-comma-decimal-input>
        </mat-form-field>
      </div>
      <div class="row">
        <h3>DGNB-Bemessungsfläche</h3>
        <mat-icon fontIcon="info" [matTooltip]="tooltips['bemessungsflaeche']"></mat-icon>
      </div>
      <div class="row">
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>ADGNB (R)</mat-label>
          <eule-comma-decimal-input formControlName="adgnb"></eule-comma-decimal-input>
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>NF Fahrzeug</mat-label>
          <eule-comma-decimal-input formControlName="nfVehicle"></eule-comma-decimal-input>
          <!-- <input matInput formControlName="nfVehicle" />
          @if(floorForm.get('vfHallCorridor')!.hasError('adgnbNegative')){
          <mat-error> ADGNB darf nicht negativ werden. </mat-error>
          } @if(floorForm.get('vfHallCorridor')!.hasError('pattern')){
          <mat-error>Eingabe muss deutschem Zahlenformat entsprechen</mat-error>
          } -->
        </mat-form-field>
        <mat-form-field class="w-100-percent smaller-font">
          <mat-label>VF Hallen,Flure</mat-label>
          <eule-comma-decimal-input formControlName="vfHallCorridor"></eule-comma-decimal-input>
          <!-- <input matInput formControlName="vfHallCorridor" />
          @if(floorForm.get('vfHallCorridor')!.hasError('adgnbNegative')){
          <mat-error> ADGNB darf nicht negativ werden. </mat-error>
          } @if(floorForm.get('vfHallCorridor')!.hasError('pattern')){
          <mat-error>Eingabe muss deutschem Zahlenformat entsprechen</mat-error>
          } -->
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="closeDialog(false)">Abbrechen</button>
  <button mat-flat-button color="primary" [disabled]="!floorForm!.dirty || !floorForm!.valid" (click)="closeDialog(true)">Übernehmen</button>
</mat-dialog-actions>
}
