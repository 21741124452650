<div class="project-info-page flexbox direction-column">
  <eule-general-title [title]="'Projektinfo'">
    @if ((isLoading$ | async) === false) {
    <div class="flexbox align-center default-gap">
      @if(projectForm.invalid){
      <div class="flexbox align-center small-gap">
        <mat-icon class="info" [matTooltip]="'Dieses Formular enthält ungültige Eingaben'">info</mat-icon>
      </div>
      }
      <button mat-raised-button color="primary" [disabled]="projectForm.invalid || !projectForm.dirty" (click)="update()">
        <mat-icon>save</mat-icon>
        Speichern
      </button>
    </div>
    }
  </eule-general-title>

  @if (isLoading$ | async) {
  <mat-spinner class="center"></mat-spinner>
  } @else {
  <mat-card class="custom-card w-100-percent">
    <mat-card-content>
      <!-- @if (!(isProjectLoading$ | async)) { -->
      <div [formGroup]="projectForm" class="container">
        <mat-tab-group dynamicHeight [(selectedIndex)]="selectedTabIndex" class="w-100-percent">
          <!-- TAB Allgemein -->
          <mat-tab>
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Allgemein', index: 0, formGroup: generalFormGroup } }"
              />
            </ng-template>
            <eule-tab-general [generalFormGroup]="generalFormGroup" [projectImage$]="projectImage$"></eule-tab-general>
          </mat-tab>

          <!-- Tab Baubeschreibung -->
          <mat-tab label="Baubeschreibung">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Baubeschreibung', index: 1, formGroup: totalBuildingDescriptionFormGroup } }"
              />
            </ng-template>
            <eule-tab-building-description [totalBuildingDescriptionFormGroup]="totalBuildingDescriptionFormGroup"></eule-tab-building-description>
          </mat-tab>

          <!-- Tab Nutzungsprofile -->
          <mat-tab label="Nutzungsprofile">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Nutzungsprofile', index: 2, formGroup: usageProfilesFormArray } }"
              />
            </ng-template>
            <eule-tab-usage-profiles [usageProfilesFormArray]="usageProfilesFormArray"></eule-tab-usage-profiles>
          </mat-tab>

          <!-- Tab Leistungsphasen -->
          <mat-tab label="Leistungsphasen">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Leistungsphasen', index: 3, formGroup: leistungsPhasenFormGroup } }"
              />
            </ng-template>
            <eule-tab-leistungsphasen [leistungsPhasenFormGroup]="leistungsPhasenFormGroup"></eule-tab-leistungsphasen>
          </mat-tab>

          <!-- Tab Zertifizierung -->
          <mat-tab label="Zertifizierung">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Zertifizierung', index: 4, formGroup: totalCertificationDescriptionFormGroup } }"
              />
            </ng-template>
            <eule-tab-certification
              [certificationFormGroup]="certificationFormGroup"
              [totalCertificationDescriptionFormGroup]="totalCertificationDescriptionFormGroup"
            ></eule-tab-certification>
          </mat-tab>

          <mat-tab label="Gebäudeparameter">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Gebäudeparameter', index: 5, formGroup: buildingParametersFormGroup } }"
              />
            </ng-template>
            <eule-tab-building-parameters [buildingParametersFormGroup]="buildingParametersFormGroup"></eule-tab-building-parameters>
          </mat-tab>

          <!-- Tab Ansprechpartner -->
          <mat-tab label="Ansprechpartner">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Ansprechpartner', index: 6, formGroup: contactPersonsFormGroup } }"
              />
            </ng-template>
            <eule-tab-contact-persons [contactPersonsFormGroup]="contactPersonsFormGroup"></eule-tab-contact-persons>
          </mat-tab>

          <!-- Tab Fotos -->
          <mat-tab label="Fotos">
            <ng-template mat-tab-label>
              <ng-container
                [ngTemplateOutlet]="tabWarnIndicator"
                [ngTemplateOutletContext]="{ $implicit: { name: 'Fotos', index: 7, formGroup: totalImagesDescriptionFormGroup } }"
              />
            </ng-template>
            <eule-tab-images [totalImagesDescriptionFormGroup]="totalImagesDescriptionFormGroup"></eule-tab-images>
          </mat-tab>
        </mat-tab-group>
      </div>
      <!-- } -->
    </mat-card-content>
  </mat-card>
  }
</div>

<ng-template #tabWarnIndicator let-data>
  <div class="flexbox align-center small-gap">
    {{ data['name'] }}
    @if (data['formGroup'].invalid) {
    <mat-icon class="info" [matTooltip]="'Dieser Tab enthält ungültige Eingaben'"> info </mat-icon>
    }
  </div>
</ng-template>
